import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const BrandLogo = () => {
  return (
    <section className="brand-logo border-b border-serv_subt md:py-6 py-4 bg-white">
      <div className="l-container flex flex-col items-center justify-center">
        <div className="flex w-full items-center">
          <div className="p-4 w-1/4 transform scale-75">
            <StaticImage
              layout="constrained"
              src="../images/brand/SpicysoftLogo.png"
              alt="スパイシーソフト株式会社"
            />
          </div>
          <div className="p-4 w-1/4 transform scale-75">
            <StaticImage
              layout="constrained"
              src="../images/brand/logo-audiobookjp-hrizonal.png"
              alt="オトバンク株式会社"
            />
          </div>
          <div className="p-4 w-1/4 transform scale-75">
            <StaticImage
              layout="constrained"
              src="../images/brand/designcase.png"
              alt="株式会社デコレーションカンパニー"
            />
          </div>
          <div className="p-4 w-1/4 transform scale-75">
            <StaticImage
              layout="constrained"
              src="../images/brand/QHD.png"
              alt="株式会社クエストホールディングス"
            />
          </div>
        </div>
        <div className="flex w-full items-center justify-center">
          <div className="p-4 w-1/4 transform scale-75">
            <StaticImage
              layout="constrained"
              src="../images/brand/maxvalu.png"
              alt="マックスバリュ関東株式会社"
            />
          </div>
          <div className="p-4 w-1/4 transform scale-75">
            <StaticImage
              layout="constrained"
              src="../images/brand/logo-cerezo-topteam.png"
              alt="セレッソ大阪"
            />
          </div>
          <div className="p-4 w-1/4 transform scale-75">
            <StaticImage
              layout="constrained"
              src="../images/brand/logo-cerezo-ladies.png"
              alt="セレッソ大阪ヤンマーレディース"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
